const SLIDER_CONFIG = {
  scroll: {
    breakpoints: {
      1200: 4,
      992: 3,
      576: 1,
      0: 0,
    },
  },
  autoplay: {
    delay: 5000,
    enabled: true,
  },
  animation: {
    behavior: 'smooth',
    scrollDebounce: 100,
  },
};

/**
 * Get container padding values
 * @param {HTMLElement} container - The carousel container
 * @returns {object} Padding values
 */
const getContainerPadding = (container) => {
  const containerStyle = getComputedStyle(container);
  return {
    paddingLeft: parseFloat(containerStyle.paddingLeft) || 0,
    paddingRight: parseFloat(containerStyle.paddingRight) || 0,
  };
};

/**
 * Get number of items to scroll based on screen width
 * @returns {number} Number of items to scroll
 */
const getScrollCount = () => {
  const width = window.innerWidth;
  const breakpoints = Object.keys(SLIDER_CONFIG.scroll.breakpoints)
    .map(Number)
    .sort((a, b) => b - a);

  for (const breakpoint of breakpoints) {
    if (width >= breakpoint) {
      return SLIDER_CONFIG.scroll.breakpoints[breakpoint];
    }
  }

  return SLIDER_CONFIG.scroll.breakpoints[SLIDER_CONFIG.scroll.breakpoints.length - 1] ?? 0;
};

/**
 * Updates scroll position to next logical item
 * @param {HTMLElement} container - The carousel container
 * @param {number} direction - Scroll direction (1 for next, -1 for previous)
 */
const scrollToNextItem = (container, direction) => {
  const currentScroll = container.scrollLeft;
  const items = [...container.children];
  const { paddingLeft } = getContainerPadding(container);
  const scrollCount = getScrollCount();

  // Find the first visible item index
  const firstVisibleIndex = items.findIndex(item => {
    const itemLeft = item.offsetLeft - container.offsetLeft - paddingLeft;
    return itemLeft >= currentScroll;
  });

  // Calculate target index based on direction and scroll count
  let targetIndex;
  if (direction > 0) {
    targetIndex = Math.min(firstVisibleIndex + scrollCount, items.length - 1);
  } else {
    targetIndex = Math.max(firstVisibleIndex - scrollCount, 0);
  }

  // Calculate target scroll position
  const targetScroll = items[targetIndex].offsetLeft - container.offsetLeft - paddingLeft;

  container.scrollTo({
    left: targetScroll,
    behavior: 'smooth'
  });
};

/**
 * Updates navigation button states
 * @param {HTMLElement} container - Carousel container
 * @param {HTMLElement} prevButton - Previous button
 * @param {HTMLElement} nextButton - Next button
 */
const updateButtonStates = (container, prevButton, nextButton) => {
  const isAtStart = container.scrollLeft <= 1;
  const isAtEnd = Math.abs(container.scrollLeft + container.offsetWidth - container.scrollWidth) <= 1;

  prevButton.disabled = isAtStart;
  nextButton.disabled = isAtEnd;
};

/**
 * Creates autoplay controller for a slider
 * @param {HTMLElement} container - Slider container
 * @param {HTMLElement} nextButton - Next button element
 * @returns {object} Autoplay controller methods
 */
const createAutoplayController = (container, nextButton) => {
  let interval = null;

  const stop = () => {
    if (interval) {
      clearInterval(interval);
      interval = null;
    }
  };

  const start = () => {
    if (!SLIDER_CONFIG.autoplay.enabled) return;
    stop();
    // If there is no need to scroll, do not start autoplay
    if (!getScrollCount()) return;

    interval = setInterval(() => {
      if (!nextButton.disabled) {
        scrollToNextItem(container, 1);
      }
    }, SLIDER_CONFIG.autoplay.delay);
  };

  return { start, stop };
};

const initBlockPSlider = () => {
  const sliders = document.querySelectorAll('.home-block-p--slider');

  sliders.forEach((slider) => {
    const container = slider.querySelector('.home-block-p__reviews-container--slider');
    const prevButton = slider.querySelector("[data-carousel-direction='previous']");
    const nextButton = slider.querySelector("[data-carousel-direction='next']");
    
    if (!container || !prevButton || !nextButton) return;

    let scrollTimeout;
    const autoplay = createAutoplayController(container, nextButton);

    const handleScroll = () => {
      clearTimeout(scrollTimeout);
      
      scrollTimeout = setTimeout(() => {
        updateButtonStates(container, prevButton, nextButton);
      }, SLIDER_CONFIG.animation.scrollDebounce);
    };

    // Event listeners
    container.addEventListener('scroll', handleScroll);
    
    // Mouse interaction listeners for container and buttons
    [container, prevButton, nextButton].forEach(element => {
      element.addEventListener('mouseenter', autoplay.stop);
      element.addEventListener('mouseleave', autoplay.start);
    });
    
    [prevButton, nextButton].forEach((button) => {
      button.addEventListener('click', () => {
        const direction = button === prevButton ? -1 : 1;
        scrollToNextItem(container, direction);
      });
    });

    // Initialize slider
    new ResizeObserver(() => {
      updateButtonStates(container, prevButton, nextButton);
    }).observe(container);

    updateButtonStates(container, prevButton, nextButton);
    autoplay.start();
  });
};

/**
 * Function to initialize blockBG functionality
 */
const initBlockP = () => {
  initBlockPSlider();
};

export default initBlockP;
