import { debounce } from './helpers';

/**
 * @typedef {object} SliderOptions - Slick slider configuration options
 * @property {boolean} infinite - Whether to enable infinite scrolling
 * @property {number} slidesToScroll - Number of slides to scroll
 * @property {number} slidesToShow - Number of slides to show
 * @property {boolean} variableWidth - Whether to enable variable width
 * @property {Array} responsive - Responsive settings
 */

/**
 * Centers arrows vertically on the first image in the slider
 * @param {object} $prevArrow - Previous arrow jQuery element
 * @param {object} $nextArrow - Next arrow jQuery element
 * @param {object} $slider - Slider container jQuery element
 */
const centerArrowsOnImage = ($prevArrow, $nextArrow, $slider) => {
  if (!($prevArrow.data('center-on-image') || $nextArrow.data('center-on-image'))) {
    return;
  }

  const $firstImage = $slider.find('img').first();
  if (!$firstImage.length) {
    return;
  }

  const imageHeight = $firstImage.height();
  const halfImageHeight = imageHeight / 2;

  if ($prevArrow.data('center-on-image')) {
    const halfPrevArrowHeight = $prevArrow.outerHeight() / 2;
    $prevArrow.css('top', `${halfImageHeight - halfPrevArrowHeight}px`);
  }

  if ($nextArrow.data('center-on-image')) {
    const halfNextArrowHeight = $nextArrow.outerHeight() / 2;
    $nextArrow.css('top', `${halfImageHeight - halfNextArrowHeight}px`);
  }
};

/**
 * Creates slider configuration based on desktop-only status
 * @param {object} params - Slider parameters
 * @param {object} params.$sliderNav - Slider navigation jQuery element
 * @param {object} params.$prevArrow - Previous arrow jQuery element
 * @param {object} params.$nextArrow - Next arrow jQuery element
 * @param {number} params.itemsPerRow - Number of items per row
 * @param {boolean} params.isDesktopOnly - Whether slider is desktop-only
 * @returns {SliderOptions} Slider configuration options
 */
const createSliderConfig = ({ $sliderNav, $prevArrow, $nextArrow, itemsPerRow, isDesktopOnly }) => {
  const baseConfig = {
    appendArrows: $sliderNav,
    infinite: false,
    nextArrow: $nextArrow,
    prevArrow: $prevArrow,
    slidesToScroll: 1,
    slidesToShow: itemsPerRow,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.12,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: itemsPerRow,
        },
      },
    ],
  };

  if (!isDesktopOnly) {
    return baseConfig;
  }

  return {
    ...baseConfig,
    responsive: [
      {
        breakpoint: 768,
        settings: 'unslick',
      },
    ],
  };
};

/**
 * Initializes a modular block slider
 * @param {object} $element - Slider container jQuery element
 * @param {boolean} isDesktopOnly - Whether slider is desktop-only
 */
const initModularBlockSlider = ($element, isDesktopOnly) => {
  const $slider = $('.js-slider, .js-carousel-desktop-only', $element);
  const $sliderNav = $('.js-slider-nav, .js-carousel-desktop-only-nav', $element);
  const $prevArrow = $('.js-slider-arrow-left, .js-carousel-desktop-only-arrow-left', $element);
  const $nextArrow = $('.js-slider-arrow-right, .js-carousel-desktop-only-arrow-right', $element);
  const itemsPerRow = $slider.data('items-per-row');

  try {
    const config = createSliderConfig({
      $sliderNav,
      $prevArrow,
      $nextArrow,
      itemsPerRow,
      isDesktopOnly,
    });

    $slider.not('.slick-initialized').slick(config);

    const handleArrowCentering = () => centerArrowsOnImage($prevArrow, $nextArrow, $slider);

    $slider.on('init', handleArrowCentering);
    $(window).on('resize', handleArrowCentering);
    $slider.find('img').on('load', handleArrowCentering);
  } catch (error) {
    console.error('Slider initialization error:', error);
  }
};

/**
 * Initializes all sliders on the page
 */
const initializeSliders = () => {
  $('.js-has-slider, .js-carousel-desktop-only').each((_, element) => {
    const $element = $(element);
    const isDesktopOnly = $element.hasClass('js-carousel-desktop-only');

    if (isDesktopOnly && $(window).width() <= 768) {
      if ($element.hasClass('slick-initialized')) {
        $element.slick('unslick');
      }
    } else if (!$element.hasClass('slick-initialized')) {
      initModularBlockSlider($element, isDesktopOnly);
    }
  });
};

/**
 * Initialize the slider module
 */
const initModularBlockSliders = () => {
  initializeSliders();
  $(window).on('resize', debounce(initializeSliders, 250));
};

export default initModularBlockSliders;
