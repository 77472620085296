import passwordValidation from "../../js/validate-password";
import initBlockBG from "./components/homeBlockTypeBG";
import initBlockP from "./components/homeBlockTypeP";
import initModularBlockSliders from "./modules/modular-block-slider";

$(document).ready(() => {
    passwordValidation();
    initModularBlockSliders();
});

initBlockBG();
initBlockP();
