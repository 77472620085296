import { copyToClipboard } from "../modules/helpers";

/**
 * Updates scroll position to next logical item
 * @param {HTMLElement} container - The carousel container
 * @param {number} direction - Scroll direction (1 for next, -1 for previous)
 */
const scrollToNextItem = (container, direction) => {
  const currentScroll = container.scrollLeft;
  const items = [...container.children];
  let targetScroll = 0;

  // Get the computed padding of the container
  const containerStyle = getComputedStyle(container);
  const paddingLeft = parseFloat(containerStyle.paddingLeft) || 0;
  const paddingRight = parseFloat(containerStyle.paddingRight) || 0;

  if (direction > 0) {
    // Find the next item that is not fully visible
    const nextItem = items.find(item => {
      const itemLeft = item.offsetLeft - container.offsetLeft - paddingLeft;
      const containerRight = currentScroll + container.clientWidth - paddingRight - paddingLeft;
      return itemLeft + item.offsetWidth > containerRight;
    });

    if (nextItem) {
      targetScroll = nextItem.offsetLeft - container.offsetLeft - paddingLeft;
    }
  } else {
    // Find the previous item
    const previousItem = [...items].reverse().find(item => {
      const itemRight = item.offsetLeft + item.offsetWidth - container.offsetLeft - paddingLeft;
      return itemRight < currentScroll;
    });

    if (previousItem) {
      targetScroll = previousItem.offsetLeft - container.offsetLeft - paddingLeft;
    } else {
      targetScroll = 0;
    }
  }

  container.scrollTo({
    left: targetScroll,
    behavior: 'smooth'
  });
};

/**
 * Updates navigation button states
 * @param {HTMLElement} container - Carousel container
 * @param {HTMLElement} prevButton - Previous button
 * @param {HTMLElement} nextButton - Next button
 */
const updateButtonStates = (container, prevButton, nextButton) => {
  const isAtStart = container.scrollLeft <= 1; // Using 1 to handle floating point
  const isAtEnd = Math.abs(
    container.scrollLeft + container.offsetWidth - container.scrollWidth
  ) <= 1;

  prevButton.disabled = isAtStart;
  nextButton.disabled = isAtEnd;
};

/**
 * Initializes block background sliders
 */
function initBlockBGSlider() {
  const sliders = document.querySelectorAll('.home-block-bg__container');

  sliders.forEach((slider) => {
    const container = slider.querySelector('.home-block-bg__carousel');
    const prevButton = slider.querySelector("[data-carousel-direction='previous']");
    const nextButton = slider.querySelector("[data-carousel-direction='next']");
    let scrollTimeout;

    if (!container || !prevButton || !nextButton) return;

    // Add scroll event listener
    container.addEventListener('scroll', () => {
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        updateButtonStates(container, prevButton, nextButton);
      }, 100);
    });

    // Add resize observer
    const resizeObserver = new ResizeObserver(() => {
      updateButtonStates(container, prevButton, nextButton);
    });
    resizeObserver.observe(container);

    // Add click handlers
    prevButton.addEventListener('click', () => {
      scrollToNextItem(container, -1);
    });

    nextButton.addEventListener('click', () => {
      scrollToNextItem(container, 1);
    });

    // Initialize button states
    updateButtonStates(container, prevButton, nextButton);
  });
}

/**
 * Function to update the button text after copying the promo code
 * @param {HTMLButtonElement} button the button to update
 */
function showCopiedConfirmation(button) {
  const {confirmation, cta} = button.dataset;
  button.textContent = confirmation;
  button.disabled = true;
  setTimeout(() => {
    button.textContent = cta;
    button.disabled = false;
  }, 2000);
}

/**
 * Function to handle button click to copy promo code to clipboard
 * @param {event} event the html event object 
 */
function handleCopyToClipboard(event) {
  const button = event.currentTarget;
  const text = button.dataset.promoCode;

  copyToClipboard(text, showCopiedConfirmation(button));
}

/**
 * Function to initialize copy to clipboard functionality
 */
function initCopyToClipboard() {
  const buttons = document.querySelectorAll('.background-image-card__copy-promo-code');
  buttons.forEach(button => {
    button.addEventListener('click', handleCopyToClipboard);
  });
}

/**
 * Function to initialize blockBG functionality
 */
export default function initBlockBG() {
  initCopyToClipboard();
  initBlockBGSlider();
}
